<script>
    // import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    // import PageHeader from "@/components/page-header";
    
    import Swal from "sweetalert2";
    import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Edit Breakdown",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            // Layout,
            // PageHeader,
        },
        props: ["idData"],
        data() {
            return {
                title: "Edit Breakdown",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Breakdown",
                        href: "/master/Breakdown",
                    },
                    {
                        text: "Edit Breakdown",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                master_role: ['one', 'two', 'three'],
                dataid: this.idData,
                role_selected: '',
                keterangan: "",
                biaya: "",
                periode_awal: "",
                periode_akhir: "",
            };
        },
        mounted() {
            this.getBreakdown();
        },
        methods:{
            getBreakdown() {
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                this.dataid = this.idData;
                let self = this;
                axios
                .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/breakdown?id=" +
                    this.dataid
                )
                .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.list_data.data[0];
                if (response_data.code == 200) {
                    self.keterangan = response_data_fix.mb_keterangan;
                    self.biaya = response_data_fix.mb_biaya;
                    self.periode_awal = response_data_fix.mb_periode_awal;
                    self.periode_akhir = response_data_fix.mb_periode_akhir;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data.message,
                    });
                }
                Swal.close();
                });
            },
            StoreData() {
                let self = this;
                Swal.fire({
                    title: '<i class="fas fa-spinner fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                });
                var FormData = require("form-data");
                var data = new FormData();
                data.append("id", self.dataid);
                data.append("keterangan", self.keterangan);
                data.append("biaya", self.biaya);
                data.append("periode_awal", self.periode_awal);
                data.append("periode_akhir", self.periode_akhir);

                var config = {
                    method: "post",
                    url:
                    process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/breakdown/update",
                    data: data,
                };
                axios(config)
                .then(function (response) {
                var response_data = response.data; 
                if(response_data.code != 200){
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    html: response_data.message,
                    });
                }else{
                    let timerInterval;
                    Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Anda akan diarahkan ke halaman master breakdown segera",
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                    }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        self.$emit("refresh-table", "update");
                    }
                    });
                }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
            },
            numberFormat(bilangan) {
                if (parseFloat(bilangan) === 0) {
                    Swal.fire({
                        icon: "warning",
                        title: "Peringatan",
                        text: "Input tidak boleh 0 pertama kali",
                    });
                    return bilangan = null;
                }
                var number_string = bilangan.replace(/[^,\d]/g, "").toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string.substr(sisa).match(/\d{3}/g);
                if (ribuan) {
                    var separator = sisa ? "." : "";
                    rupiah += separator + ribuan.join(".");
                }

                return rupiah;
            },
        },
    };
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <b-form class="p-2" @submit.prevent="StoreData">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <b-form-group
                                    class="mb-3"
                                    label="keterangan"
                                    label-for="formrow-keterangan-input"
                                >
                                    <b-form-input
                                    id="formrow-keterangan-input"
                                    type="text"
                                    v-model="keterangan"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-12">
                                <label>Breakdown Biaya Sponsorship</label>
                                <input type="text" class="form-control" v-model="biaya" v-on:input="
                                                        biaya = numberFormat(
                                                            biaya
                                                        )" maxlength="15"/>
                            </div>
                            <div class="col-md-12">
                                <b-form-group
                                    class="mb-3"
                                    label="Periode Awal"
                                    label-for="formrow-periode_awal-input"
                                >
                                    <b-form-input
                                    id="formrow-periode_awal-input"
                                    type="date"
                                    class="form-control"
                                    v-model="periode_awal"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-12">
                                <b-form-group
                                    class="mb-3"
                                    label="Periode Akhir"
                                    label-for="formrow-periode_akhir-input"
                                >
                                    <b-form-input
                                    id="formrow-periode_akhir-input"
                                    type="date"
                                    class="form-control"
                                    v-model="periode_akhir"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                        <b-button type="reset" variant="danger" class="m-1"><i class="fa fa-refresh"></i> Reset</b-button>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
